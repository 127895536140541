.dashboardContainer {
    padding: 24px;
}

.gutter-box {
    text-align: center;
}

#root>div>section>section>main>div>div>div.ant-tabs-content.ant-tabs-content-no-animated.ant-tabs-top-content.ant-tabs-card-content>div.ant-tabs-tabpane.ant-tabs-tabpane-active>div.ant-skeleton.ant-skeleton-active>div {
    padding: 10px;
}