.ticketRow {
    /* padding: 3px 18px !important; */
    cursor: pointer;
}

.ddd {
    background-color: #d6e4ff;
}

/* .colDivider{
    overflow: scroll
} */

.ticketDescription .ant-descriptions {
    padding: 16px;
    border-bottom: solid 1px #d9d9d9;
}