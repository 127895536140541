.loginContainer {
    height: 100%;
    display: flex;
}

.loginLeft {
    flex: 1;
    background: linear-gradient(-45deg, #cf7e65, #e73c7ebf, #28b8ec, #8823d5);
    background-size: 400% 400%;
    animation: gradient 30s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    30% {
        background-position: 50% 50%;
    }

    70% {
        background-position: 100% 100%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.loginRight {
    padding: 0px 55px;
    align-self: center;
    background: white;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    height: 100%;
}

.loginForm {
    max-width: 300px;
}

#image-credit {
    position: absolute;
    bottom: 0;
    color: lightgrey;
    padding: 5px 10px;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.loginContainer .logoHeader {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
}


.loginContainer .footer {
    flex: 1;
}