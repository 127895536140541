.react-resizable-handle {
    /* position: absolute; */
    width: 10px;
    height: 100%;
    /* bottom: 0;
    right: -5px; */
    cursor: col-resize;
    z-index: 1;
}

.react-resizable {
    position: relative;
    background-clip: padding-box;
}