/* @import '~antd/dist/antd.less';   */
/* @import 'theme.less';  */
#root {
    height: 100%;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.fl-jus-cen {
    display: flex !important;
    justify-content: center;
}

.pr-5 {
    padding-right: 5px !important;
}

.gutter-box {

    text-align: center !important;
}

.h-100 {
    height: 100% !important;
}

.ph-10 {
    padding: 0px 16px;
}

@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?f0i9kx');
    src: url('fonts/icomoon.eot?f0i9kx#iefix') format('embedded-opentype'),
        url('fonts/icomoon.ttf?f0i9kx') format('truetype'),
        url('fonts/icomoon.woff?f0i9kx') format('woff'),
        url('fonts/icomoon.svg?f0i9kx#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-mail-checked:before {
    content: "\e903";
}

.icon-mail-checked1:before {
    content: "\e904";
}

.icon-mail-envelope-closed:before {
    content: "\e905";
}

.icon-mail-envelope-closed1:before {
    content: "\e906";
}

.icon-mail-envelope-open:before {
    content: "\e902";
}